import React from 'react';

import Page from '@/templates/page';

const PagePreview = (props) =>
  <Page
    {...props}
    type={'page'}
  />;

export default PagePreview;
